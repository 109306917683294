<template>
    <div class="pl-pay-fail-wrap pl-flex-start">
        <div class="pl-fail-icon">
            <img src="./TF-icon/F.png">
        </div>
        <div class="pl-pay-for-results">支付失败，请重试！</div>
        <div class="pl-view-or-return pl-flex">
            <a :href="`/user/order/detail?id=${$route.query.id}`">查看订单</a>
            <a href="/">返回首页</a>
        </div>
        <div class="pl-recommend-commodities">
            <div class="module-title">推荐商品</div>
            <div class="pl-recommend-commodities-content pl-flex">

                <a :href="`/goods/detail?id=${item.id}`" :key="index" v-for="(item, index) in list">
                    <div class="pl-product-image">
                         <img :src="item.cover">
                    </div>
                    <div class="pl-product-content">
                        <div class="pl-product-name">{{item.name}}</div>
                        <!-- <div class="pl-product-introduction">气垫元件</div>
                        <div class="pl-product-price">￥50.00</div> -->
                    </div>
                </a>

            </div>
        </div>
    </div>
</template>
<script>
import {recommandGoods} from '@/api/shoping'
export default {
    data() {
        return {
            list: []
        }
    },
    created() {
        recommandGoods().then((res) => {
            let list = res || []
            list.forEach(item => {
                let arr = item.goods || []
                this.list.push(...arr)
            })
        })
    }
}
</script>
<style lang="scss" scoped>
    *{
        font-size: 16px;
    }
    a{
        display: block;
        text-decoration: none;
        color: #000;
    }
    .pl-pay-fail-wrap{
        flex-direction: column;
    }
    .pl-fail-icon{
        width: 100px;
        height: 100px;
        margin: 100px 0px 20px;
    }
    .pl-pay-for-results{
        color: #D81E06;
    }
    .pl-fail-icon img{
        width: 100px;
    }
    .pl-view-or-return{
        width: 380px;
        margin: 30px;
    }
    .pl-view-or-return a{
        width: 170px;
        line-height: 36px;
        color: #3385FF;
        background-color: #ffffff;
        border: 1px #3385FF solid;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .pl-view-or-return a:hover{
        box-shadow:0px 0px 2px #3385FF;
    }
    .pl-view-or-return a:active{
        color: #ffffff;
        background-color: #3385FF;
    }
    .pl-recommend-commodities-content{
        width: 810px;
        flex-wrap: wrap;
    }
    .pl-recommend-commodities-content a{
        display: flex;
        justify-content: flex-start;
        height: 90px;
        width: 20%;
        margin-right: 1%;
        margin-bottom: 15px;
    }
    .pl-product-image,.pl-product-content{
        border-radius: 5px;
        overflow: hidden;
    }
    .pl-product-image img{
        width: 82px;
        border-radius: 5px;
    }
    .pl-product-image {
        width: 82px;
    }
    .pl-product-content{
        position: relative;
        width:60%;
    }
    .pl-product-content div{
        margin-left: 5px;
    }
    .pl-product-name{
        margin-top: 10px;
    }
    .pl-product-introduction{
        font-size: 14px;
        margin-top: 5px;
    }
    .pl-product-price{
        position: absolute;
        bottom: 10px;
        color: #3385FF;
    }
</style>